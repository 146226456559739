import axios from "axios";
import React, { useEffect, useState } from "react";
import { colors, izr_server } from "../config";
import "react-slideshow-image/dist/styles.css";
import { Fade, Slide } from "react-slideshow-image";
import { Image, VStack } from "@chakra-ui/react";
import IText from "./IText";

interface props {
  onEnd: () => void;
  adhkar: boolean;
}
function EventSlider({ onEnd, adhkar }: props) {
  const [events, setEvents] = useState([""]);
  const [Opacity, setOpacity] = useState("0");
  const [duration, setDuration] = useState(6);

  const adhkars = [
    "أَسْـتَغْفِرُ الله، أَسْـتَغْفِرُ الله، أَسْـتَغْفِرُ الله",
    "اللّهُـمَّ أَنْـتَ السَّلامُ ، وَمِـنْكَ السَّلام ، تَبارَكْتَ يا ذا الجَـلالِ وَالإِكْـرام ",
    "لا إلهَ إلاّ اللّهُ وحدَهُ لا شريكَ لهُ، لهُ المُـلْكُ ولهُ الحَمْد، وهوَ على كلّ شَيءٍ قَدير، اللّهُـمَّ لا مانِعَ لِما أَعْطَـيْت، وَلا مُعْطِـيَ لِما مَنَـعْت، وَلا يَنْفَـعُ ذا الجَـدِّ مِنْـكَ الجَـد",
    "لا إلهَ إلاّ اللّه, وحدَهُ لا شريكَ لهُ، لهُ الملكُ ولهُ الحَمد، وهوَ على كلّ شيءٍ قدير، لا حَـوْلَ وَلا قـوَّةَ إِلاّ بِاللهِ، لا إلهَ إلاّ اللّـه، وَلا نَعْـبُـدُ إِلاّ إيّـاه, لَهُ النِّعْـمَةُ وَلَهُ الفَضْل وَلَهُ الثَّـناءُ الحَـسَن، لا إلهَ إلاّ اللّهُ مخْلِصـينَ لَـهُ الدِّينَ وَلَوْ كَـرِهَ الكـافِرون",
    "لا إلهَ إلاّ اللّهُ وحْـدَهُ لا شريكَ لهُ، لهُ المُلكُ ولهُ الحَمْد، يُحيـي وَيُمـيتُ وهُوَ على كُلّ شيءٍ قدير ",
    "لا إلهَ إلاّ اللّهُ وَحْـدَهُ لا شريكَ لهُ، لهُ الملكُ ولهُ الحَمْد، وهُوَ على كُلّ شَيءٍ قَـدير ",
    "اللّهُـمَّ إِنِّـي أَسْأَلُـكَ عِلْمـاً نافِعـاً وَرِزْقـاً طَيِّـباً ، وَعَمَـلاً مُتَقَـبَّلاً ",
    "اللَّهُمَّ أَعِنِّي عَلَى ذِكْرِكَ وَشُكْرِكَ وَحُسْنِ عِبَادَتِكَ ",
    "اللّهُ لاَ إِلَـهَ إِلاَّ هُوَ الْحَيُّ الْقَيُّومُ لاَ تَأْخُذُهُ سِنَةٌ وَلاَ نَوْمٌ لَّهُ مَا فِي السَّمَاوَاتِ وَمَا فِي الأَرْضِ مَن ذَا الَّذِي يَشْفَعُ عِنْدَهُ إِلاَّ بِإِذْنِهِ يَعْلَمُ مَا بَيْنَ أَيْدِيهِمْ وَمَا خَلْفَهُمْ وَلاَ يُحِيطُونَ بِشَيْءٍ مِّنْ عِلْمِهِ إِلاَّ بِمَا شَاء وَسِعَ كُرْسِيُّهُ السَّمَاوَاتِ وَالأَرْضَ وَلاَ يَؤُودُهُ حِفْظُهُمَا وَهُوَ الْعَلِيُّ الْعَظِيمُ",
    "سُـبْحانَ اللهِ، والحَمْـدُ الله ، واللهُ أكْـبَر ",
  ];
  useEffect(() => {
    setTimeout(() => {
      setOpacity("1");
    }, 1000);
  }, []);

  const spanStyle = {
    padding: "20px",
    background: "#efefef",
    color: "#000000",
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };
  useEffect(() => {
    const asfn = async () => {
      await axios
        .get<{ events: [{ flyerTV: string }] }>(izr_server + "/getEvents/all")
        .then((response) => {
          let events: string[] = [];
          response.data.events.forEach((event) => {
            events.push(event.flyerTV);
          });
          events.push("https://izr-cloud.online/getEvents/APPFlyer.jpg");
          console.log(events);
          setEvents(events);
        });
    };
    !adhkar && asfn();
  }, []);
  function wordsLen(str: string) {
    const array = str.trim().split(/\s+/);
    return array.length;
  }
  return (
    <Fade
      easing="ease" // duration={5000}
      duration={adhkar ? duration * 500 : 10000}
      arrows={false}
      onChange={(from, to) => {
        console.log("chnaging ", from, to);
        if (!adhkar) {
          if (events.length === 1) onEnd();

          to === events.length - 1 &&
            setTimeout(() => {
              onEnd();
            }, 10000);
          return;
        }
        setDuration(wordsLen(adhkars[to]));
        to === adhkars.length - 1 &&
          setTimeout(() => {
            onEnd();
          }, wordsLen(adhkars[to]) * 500);
        console.log("Current Index: " + to);
        console.log("Current Duration: ", wordsLen(adhkars[to]) * 500);
      }}
    >
      {!adhkar &&
        events &&
        events.map((slideImage, index) => (
          <div
            className=""
            style={{
              backgroundImage: `url(${slideImage})`,
              height: "100vh",
              // width: "100vw",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          ></div>
        ))}
      {adhkar &&
        adhkar &&
        adhkars.map((dhikr, index) => (
          <div
            className=""
            style={{
              height: "100vh",
              // width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1vw",
            }}
          >
            <IText
              style={{
                fontSize: "4vw",
                textAlign: "center",
                direction: "rtl",
                color: "white",
                fontWeight: "500",
                background: colors.grad,
                padding: "5vw",
                borderRadius: "5vw",
              }}
            >
              {dhikr}
              <br />
              {index === adhkars.length - 1 && "ثلاثا وثلاثين"}
            </IText>
          </div>
        ))}
    </Fade>
  );
}

export default EventSlider;
